import React from "react";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
      backgroundColor: "#FBC950", // Light yellow background color
      borderRadius: "5px", // Rounded corners
      // padding: '10px', // Padding inside the input
    },
    "& .MuiInputBase-input": {
      textAlign: "left", // Center the text
      fontSize: "22px", // Increase font size
      fontWeight: "bold", // Make the text bold
      color: "#2C3E50", // Text color
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F9D46D", // Border color
    },
    "& .MuiSvgIcon-root": {
      color: "#2C3E50", // Icon color
    },
  });
  

// export default function SwipeNumberField({
//    ...props
// }) {

//   return (
//     <NumericFormat
//         defaultValue={8}
//         customInput={CustomTextField}
//         suffix="%"
//         // isAllowed={(values) => {
//         //     const {value} = values;
//         //     return value >= 0 &&  value <= 100;
//         // }}
//         {...props}
//     ></NumericFormat>
//   );
// }
const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange,min=0,max,  ...other } = props;
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
        isAllowed={(values) => {
            if (!max) {
                return true;
            }
            const {value} = values;
            return value >= min &&  value <= max;
        }}
        />
      );
    },
  );

export default function SwipeNumberField({
    ...props
 }) {
    const { defaultValue = 0, suffix = '', prefix = '', onChange , ...other} = props;
   return (
    <CustomTextField
        InputProps={{
          inputComponent: NumericFormatCustom,
          inputProps: {
            suffix: suffix,
            defaultValue: defaultValue,
            onChange: onChange,
            value: other.value,
            min: other.min,
            max: other.max,
            prefix: prefix
          }
        }}
        {...other}
      />
   );
 }